<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'VerifyEmail',
  mounted() {
    this.verifyEmail();
  },
  methods: {
    ...mapActions(['verifyUserEmail']),
    verifyEmail() {
      const verificationToken = this.$route.query?.token;
      this.verifyUserEmail({ verificationToken });
    }
  }
};
</script>
